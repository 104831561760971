import React, { useState, useEffect } from "react";
import { Breadcrumb, Col, Input, Layout, Row, Select, Form } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";

import { Button } from "antd";
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getCountry } from "../../appRedux/actions/Country";
import { getEmployee } from "../../appRedux/actions/Employee";

const { Option } = Select;

const EditEmployee = (props) => {
    const [form] = Form.useForm();
    const employeeID = props.match.params.id;
    const history = useHistory();
    const dispatch = useDispatch();
    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);

    let countries = useSelector(({ country }) => country.countries);

    useEffect(() => {
        dispatch(getEmployee(employeeID));
        dispatch(getCountry());
    }, []);

    const onFinish = (values) => {
        setLoading(true);
        console.log(values);

        axios
            .post(
                config.apiserver + `customer/customerupdate/${employeeID}`,
                values,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    setLoading(false);
                    CisUI().Notification("success", res.data.msg);
                    history.push("../customerlist");
                } else {
                    setLoading(false);
                    CisUI().Notification("error", res.data.msg);
                    history.push("../customerlist");
                }
            })
            .catch((errors) => {
                setLoading(false);
            });
    };

    const getSingleEmployee = () => {
        setLoading(true);
        axios
            .get(
                config.apiserver + `emaployee/emaployeeedit/${employeeID}`,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    setLoading(false);
                    setEmployee(res.data.data);
                    console.log(res.data.data);
                    console.log("hello");
                } else {
                    setLoading(false);
                    console.log("error");
                }
            })
            .catch((errors) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getSingleEmployee();
    }, []);

    return loading ? (
        <Loading />
    ) : (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">Edit Employee</h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>Edit Employee</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                {employee == "" ? (
                                    <Loading />
                                ) : (
                                    <Form
                                        onFinish={onFinish}
                                        // initialValues={{
                                        //     user_name: employee.user_name,
                                        //     name: employee.name,
                                        //     phone: employee.phone,
                                        //     email: employee.email,
                                        //     address: employee.address,
                                        //     city: employee.city,
                                        //     state: employee.state,
                                        //     country: employee.country,
                                        //     zip_code: employee.zip_code,
                                        //     status: employee.status.toString(),
                                        // }}
                                        form={form}
                                        className="cis_form"
                                        layout="vertical"
                                    >
                                        <Row
                                            gutter={[24]}
                                            className="cis_form_row"
                                        >
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Username"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Username is required",
                                                        },
                                                    ]}
                                                    // initialValue={employee.user_name}
                                                    name="user_name"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="Username" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Name is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="name"
                                                >
                                                    <Input placeholder="Name" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Phone"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Phone is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="phone"
                                                >
                                                    <Input placeholder="Phone" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Email is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="email"
                                                >
                                                    <Input placeholder="Email" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Address"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Address is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="address"
                                                >
                                                    <Input placeholder="Address" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="City"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "City is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="city"
                                                >
                                                    <Input placeholder="City" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="State"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "State is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="state"
                                                >
                                                    <Input placeholder="State" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Country"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Country is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="country"
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select Your Country"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            option.children
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    input.toLowerCase()
                                                                ) >= 0
                                                        }
                                                    >
                                                        {countries.map(
                                                            (
                                                                country,
                                                                index
                                                            ) => (
                                                                <Option
                                                                    key={
                                                                        ++index
                                                                    }
                                                                    value={
                                                                        country.id
                                                                    }
                                                                >
                                                                    {
                                                                        country.name
                                                                    }
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Zip Code"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Zip Code is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="zip_code"
                                                >
                                                    <Input placeholder="Zip Code" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="User Status"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "User Status is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="status"
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select An Option"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name="status"
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            option.children
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    input.toLowerCase()
                                                                ) >= 0
                                                        }
                                                    >
                                                        <Option value="1">
                                                            Active
                                                        </Option>
                                                        <Option value="0">
                                                            Inactive
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col
                                                lg={24}
                                                xs={24}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    type="secondary"
                                                    htmlType="reset"
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default EditEmployee;
