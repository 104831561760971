import {
    GET_ALL_EMPLOYEE,
    GET_EMPLOYEE_DETAILS,
    DELETE_EMPLOYEE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
    employees: [],
    employee: [],
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_EMPLOYEE: {
            return { ...state, employees: payload };
        }

        case GET_EMPLOYEE_DETAILS: {
            return { ...state, employee: payload };
        }

        case DELETE_EMPLOYEE:
            return {
                ...state,
                employees: state.employees.filter(
                    ({ id }) => id !== payload.id
                ),
            };

        default:
            return state;
    }
};
