import React from "react";
import { notification, Tag } from "antd";
import moment from "moment";

const CisUI = () => {
    const getStatusLabel = (status) => {
        let statusLB = { status };
        if (status === 1) {
            statusLB = (
                <Tag color="#34495e" className="list-tag-name">
                    Active
                </Tag>
            );
        } else if (status === 0) {
            statusLB = (
                <Tag color="#750707" className="list-tag-name">
                    Inactive
                </Tag>
            );
        } else {
            statusLB = (
                <Tag color="#750707" className="list-tag-name">
                    {status}
                </Tag>
            );
        }
        return statusLB;
    };

    const getEditStatusLabel = (status) => {
        let statusLB = { status };
        if (status === 1) {
            statusLB = "Active";
        } else {
            statusLB = "Inactive";
        }
        return statusLB;
    };

    const getOrderStatus = (status) => {
        let statusLB = { status };
        if (status === "Active") {
            statusLB = <Tag color="#34495e">{status}</Tag>;
        } else if (status === 1) {
            statusLB = <Tag color="#34495e">Approved</Tag>;
        } else if (status === 0) {
            statusLB = <Tag color="#750707">Pending</Tag>;
        } else if (status === 3) {
            statusLB = <Tag color="#750707">Cancelled</Tag>;
        } else {
            statusLB = <Tag color="#750707">{status}</Tag>;
        }
        return statusLB;
    };

    const getTicketStatus = (status) => {
        let statusLB = { status };
        if (status === 0) {
            statusLB = <Tag color="#34495e">Open</Tag>;
        } else if (status === 1) {
            statusLB = <Tag color="#52c41a">Closed</Tag>;
        } else if (status === 2) {
            statusLB = <Tag color="#750707">Customer-Reply</Tag>;
        } else if (status === 3) {
            statusLB = <Tag color="#750707">Answered</Tag>;
        } else {
            statusLB = <Tag color="#750707">{status}</Tag>;
        }
        return statusLB;
    };

    const getInvoiceStatus = (status) => {
        let statusLB = { status };
        if (status === "Paid") {
            statusLB = <Tag color="#34495e">{status}</Tag>;
        } else if (status === "Unpaid") {
            statusLB = <Tag color="#750707">Unpaid</Tag>;
        } else if (status === 1) {
            statusLB = <Tag color="#34495e">Paid</Tag>;
        } else if (status === 0) {
            statusLB = <Tag color="#750707">Unpaid</Tag>;
        } else {
            statusLB = <Tag color="#750707">{status}</Tag>;
        }
        return statusLB;
    };

    const formItemLayout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const hideUnordered = false;
    const perPage = 50;

    const headers = {
        "Content-Type": "application/json",
        Authorization: "superadmin:123456",
    };

    const HeaderRequest = () => {
        const token = localStorage.getItem("token");
        return { headers: { Authorization: token } };
    };

    const getLocalUserData = () => {
        const userData = localStorage.getItem("userData");
        return userData;
    };

    const CurrencyFormat = (x) => {
        let number = parseFloat(x).toFixed(2);
        return "$" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const FullName = (fname, lname) => {
        return fname + " " + lname;
    };

    const DateFormat = (data) => {
        // return moment(data).format("MM/DD/YYYY");
        if (data !== null) {
            return moment(data).format("MM-DD-YYYY");
        }
    };

    const DateFormatWithTime = (data) => {
        // return moment(data).format("MM/DD/YYYY hh:mm:ss");
        if (data !== null) {
            return moment(data).format("MM-DD-YYYY hh:mm:ss");
        }
    };

    const numberFormat = (value) =>
        new Intl.NumberFormat("en-us", {
            style: "currency",
            currency: "USD",
        }).format(value);

    const getCurrencyFormated = (amount) => {
        return (
            <div style={{ textAlign: "right", marginRight: "30px" }}>
                {numberFormat(amount || 0)}
            </div>
        );
    };

    const getCurrencyFormated1 = (amount) => {
        if (amount >= 0) {
            return numberFormat(amount || 0);
        } else {
            const amt = numberFormat(amount || 0);
            return "(" + amt.replace("-", "") + ")";
        }
    };

    const getNumber = (amount) => {
        return new Intl.NumberFormat("en-us").format(amount);
    };

    const getQueryStrings = (values) => {
        const qs = Object.keys(values)
            .map((key) => {
                if (
                    values[key] === undefined ||
                    values[key] === "" ||
                    values[key] === null
                ) {
                    return `${key}=${""}`;
                } else {
                    console.log(key + ":" + values[key]);
                    if (key === "date") {
                        return `${key}=${CisUI().ParseDateFormat(values[key])}`;
                    } else {
                        return `${key}=${values[key]}`;
                    }
                }
            })
            .join("&");

        return qs.replaceAll("undefined", "");
    };

    const getBillSatus = (statuscode) => {
        let sts = <Tag color="#34495e">Pending</Tag>;
        if (statuscode === 0) {
            sts = <Tag color="#34495e">Pending</Tag>;
        } else if (statuscode === 1) {
            sts = <Tag color="#34495e">Approved</Tag>;
        } else if (statuscode === 3) {
            sts = <Tag color="#34495e">Reject</Tag>;
        }
        return sts;
    };

    const Notification = (type, message) => {
        switch (type) {
            case "success":
                return notification.success({
                    type: type,
                    message: "Success",
                    description: message,
                });

            case "error":
                return notification.error({
                    type: type,
                    message: "Error",
                    description: message,
                });

            case "warning":
                return notification.warning({
                    type: type,
                    message: "Warning",
                    description: message,
                });

            case "info":
                return notification.info({
                    type: type,
                    message: "Info",
                    description: message,
                });

            default:
                return false;
        }
    };

    return {
        hideUnordered: hideUnordered,
        perPage: perPage,
        formItemLayout: formItemLayout,
        headers: headers,
        getStatusLabel: getStatusLabel,
        getBillSatus: getBillSatus,
        getOrderStatus: getOrderStatus,
        HeaderRequest: HeaderRequest(),
        CurrencyFormat: CurrencyFormat,
        FullName: FullName,
        DateFormat: DateFormat,
        DateFormatWithTime: DateFormatWithTime,
        getCurrency: getCurrencyFormated,
        getCurrencyFormated1: getCurrencyFormated1,
        getNumber: getNumber,
        getQueryStrings: getQueryStrings,
        getInvoiceStatus: getInvoiceStatus,
        getTicketStatus: getTicketStatus,
        getLocalUserData: getLocalUserData,
        Notification,
        getEditStatusLabel,
    };
};

export { CisUI };
