import React, { useState } from "react";
import {Breadcrumb, Input, Layout, Card, Button, Table, Menu, Dropdown, Modal,} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";

import axios from "axios";

import { CisUI } from "../../util/CISUI";
import { EllipsisOutlined } from "@ant-design/icons";
import config from "../../util/config";

const { Search } = Input;
const onSearch = (value) => console.log(value);

const columns = [
	{
		title: "SL",
		dataIndex: "sl",
		key: "sl",
	},
	{
		title: "Invoice",
		dataIndex: "invoice",
		key: "invoice",
	},
	{
		title: "Invoice Date",
		dataIndex: "invoiedate",
		key: "invoiedate",
	},
	{
		title: "Due Date",
		dataIndex: "duedate",
		key: "duedate",
	},
	{
		title: "Total",
		dataIndex: "total",
		key: "total",
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
	},
];

const Invoices = () => {
	const history = useHistory();
	const [customers, setCustomers] = useState([]);

	const actionMenu = (id) => {
		const menu = (
			<Menu>
				<Menu.Item>
					<Link to={`/customer/editcustomer/${id}`}>
						<Button
							size="small"
							type="primary"
							className="list-table-btn"
							block
						>
							Edit
						</Button>
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Button
						size="small"
						type="primary"
						className="list-table-btn"
						block
						onClick={() => {
							showConfirm(id);
						}}
					>
						Delete
					</Button>
				</Menu.Item>
			</Menu>
		);

		return menu;
	};
	// const getCustomers = () => {
	// 	axios
	// 		.get(config.apiserver + "customer/customerlist", CisUI().HeaderRequest)
	// 		.then((res) => setCustomers(res.data.data))
	// 		.catch((errors) => console.log(errors));
	// };

	function showConfirm(customerID) {
		Modal.confirm({
			title: "Do you Want to delete these items?",
			content: "Some descriptions",
			okText: "Ok",
			cancelText: "Cancel",
			onOk() {
				axios
					.delete(
						config.apiserver + `customer/customerdelete/${customerID}`,
						CisUI().HeaderRequest
					)
					.then((res) => {
						if (res.data.status == 1) {
							console.log("Making Request");

							setCustomers(
								customers.filter((customer) => customer.id !== customerID)
							);
						} else {
							console.log("error");
						}
					})
					.catch((errors) => console.log(errors));
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	}

	// useEffect(() => {
	// 	getCustomers();
	// }, []);

	const data = [];

	{/*customers.map((customer, i) => {*/}
	{/*	let key = customer.id;*/}
	{/*	let sl = i;*/}
	{/*	let name = customer.name;*/}
	{/*	let email = customer.email;*/}
	{/*	let phone = customer.phone;*/}
	{/*	let status = customer.status;*/}
	
	{/*	data.push({*/}
	{/*		key: key,*/}
	{/*		sl: ++sl,*/}
	{/*		name: name,*/}
	// 		email: email,
	// 		phone: phone,
	// 		status: CisUI().getInvoiceStatus(status),
	{/*		action: (*/}
	// 			<Dropdown overlay={actionMenu(key)} placement="bottomCenter">
	// 				<Button className="list-table-btn" size="small">
	// 					<EllipsisOutlined />
	// 				</Button>
	// 			</Dropdown>
	// 		),
	// 	});
	// });

	return (
		<main className="ant-layout-content gx-layout-content gx-container-wrap ">
			<div className="home-main-content">
				<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
					{/*<Sider className="gx-algolia-sidebar">*/}
					{/*  <SupportPin />*/}
					{/*  <YourInfo />*/}
					{/*  <HomeSideNav />*/}
					{/*</Sider>*/}
					<div className="gx-algolia-main">
						<h1 className="home-top-header">Invoices</h1>
						<Breadcrumb className="home-top-breadcrumb">
							<Breadcrumb.Item>
								<Link to="/">Portal Home</Link>
							</Breadcrumb.Item>
							<Breadcrumb.Item>Invoices</Breadcrumb.Item>
						</Breadcrumb>
						<Content className="gx-algolia-content">
							<div className="gx-algolia-content-inner">
								<Card className="tickets-card">
									<Card
										type="inner"
										title="Showing 1 to 1 of 1 entries"
										extra={
											<Search
												placeholder="input search text"
												onSearch={onSearch}
											/>
										}
									>
										<Table scroll={{x: true}} columns={columns} dataSource={data} />
									</Card>
								</Card>
							</div>
						</Content>
					</div>
				</Layout>
			</div>
		</main>
	);
};

export default Invoices;
