import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Input,
    Layout,
    Card,
    Menu,
    Button,
    Dropdown,
    Modal,
    notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { Table } from "antd";
import axios from "axios";

import { CisUI } from "../../util/CISUI";
import config from "../../util/config";
import {
    EllipsisOutlined,
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import Loading from "../../components/Loading";

const columns = [
    {
        title: "SL",
        dataIndex: "sl",
        key: "sl",
    },
    {
        title: "Ticket No",
        dataIndex: "ticket_no",
        key: "ticket_no",
        render: (ticket_no, record) => (
            <Link to={`./view-ticket/${record.key}`}>{ticket_no}</Link>
        ),
    },
    {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
    },
    {
        title: "Support Pin",
        dataIndex: "support_pin",
        key: "support_pin",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
    },
    {
        title: "Last Updated",
        dataIndex: "update",
        key: "update",
    },
    {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
    },
];

const TicketList = () => {
    const [tableData, setTableData] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const publishDate = (orgianlDate) => {
        var date = new Date(orgianlDate);
        date = date.toDateString();
        return date;
    };

    const handleSearch = (e) => {
        console.log(e.target.value);

        const salesproduct = [];

        tickets.forEach((ticket, i) => {
            if (
                ticket.subject
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                ticket.ticket_no
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            ) {
                salesproduct.push({
                    key: ticket.id,
                    sl: ++i,
                    ticket_no: ticket.ticket_no,
                    subject: ticket.subject,
                    support_pin: ticket.support_pin,
                    status: CisUI().getTicketStatus(ticket.status),
                    update: publishDate(ticket.created_at),
                    actions: (
                        <Dropdown
                            overlay={actionMenu(ticket.id)}
                            placement="bottomCenter"
                        >
                            <Button className="list-table-btn" size="small">
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    ),
                });
            }
        });
        setTableData(salesproduct);
    };

    const getTableData = () => {
        const data = [];

        tickets.map((ticket, i) => {
            data.push({
                key: ticket.id,
                sl: ++i,
                ticket_no: ticket.ticket_no,
                subject: ticket.subject,
                support_pin: ticket.support_pin,
                status: CisUI().getTicketStatus(ticket.status),
                update: publishDate(ticket.created_at),
                actions: (
                    <Dropdown
                        overlay={actionMenu(ticket.id)}
                        placement="bottomCenter"
                    >
                        <Button className="list-table-btn" size="small">
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                ),
            });
        });
        setTableData(data);
    };

    useEffect(() => {
        getTableData();
    }, [tickets]);

    const userData = JSON.parse(CisUI().getLocalUserData());
    let userArea = userData["area"];
    const getUser = userArea === 1 ? "admin" : "customer";

    const actionMenu = (id) => {
        const menu = (
            <Menu>
                <Menu.Item key="1">
                    <Link to={`/${getUser}/support/view-ticket/${id}`}>
                        <Button
                            size="small"
                            type="primary"
                            className="list-table-btn"
                            block
                        >
                            View
                        </Button>
                    </Link>
                </Menu.Item>
                {/* <Menu.Item>
					<Link to={`/${getUser}/support/edit-ticket/${id}`}>
						<Button
							size="small"
							type="primary"
							className="list-table-btn"
							block
						>
							Edit
						</Button>
					</Link>
				</Menu.Item> */}
                {userArea === 1 && (
                    <Menu.Item key="3">
                        <Button
                            size="small"
                            type="primary"
                            className="list-table-btn"
                            block
                            onClick={() => {
                                showConfirm(id);
                            }}
                        >
                            Delete
                        </Button>
                    </Menu.Item>
                )}
            </Menu>
        );
        return menu;
    };

    const getTickets = () => {
        setLoading(true);
        axios
            .get(
                config.apiserver + "customersupport/customersupportlist",
                CisUI().HeaderRequest
            )
            .then((res) => {
                console.log(res.data.data);
                setTickets(res.data.data);
                setLoading(false);
            })
            .catch((errors) => console.log(errors));
    };

    function showConfirm(serviceID) {
        Modal.confirm({
            title: "Do you Want to delete these items?",
            content: "Some descriptions",
            okText: "Ok",
            cancelText: "Cancel",
            onOk() {
                axios
                    .delete(
                        config.apiserver +
                            `customersupport/customersupportdelete/${serviceID}`,
                        CisUI().HeaderRequest
                    )
                    .then((res) => {
                        if (res.data.status == 1) {
                            // console.log("Making Request");

                            setTickets(
                                tickets.filter(
                                    (service) => service.id !== serviceID
                                )
                            );
                            notification.success({
                                message: "Success",
                                type: "success",
                                description: res.data.msg,
                            });
                        } else {
                            // console.log("error");

                            notification.error({
                                message: "Error",
                                type: "error",
                                description: res.data.msg,
                            });
                        }
                    })
                    .catch((errors) => console.log(errors));
            },
            onCancel() {
                console.log("Cancel");
            },
        });
        console.log(serviceID);
    }

    useEffect(() => {
        getTickets();
    }, []);

    return loading ? (
        <Loading />
    ) : (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">
                            My Support Tickets
                            <Link to="./open-ticket">
                                <Button
                                    className="title-btn"
                                    icon={<PlusOutlined />}
                                />
                            </Link>
                        </h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>My Tickets</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                <Card className="tickets-card">
                                    <Card
                                        type="inner"
                                        extra={
                                            <Input
                                                onChange={handleSearch}
                                                size="large"
                                                placeholder="Search Ticket"
                                                addonAfter={<SearchOutlined />}
                                            />
                                        }
                                    >
                                        <Table
                                            scroll={{ x: true }}
                                            columns={columns}
                                            dataSource={tableData}
                                        />
                                    </Card>
                                </Card>
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default TicketList;
