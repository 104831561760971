import React from "react";
import { Route, Switch } from "react-router-dom";
// import AddService from '../../../containers/Service/AddService';
import EditService from "../../../containers/Service/EditService";
import MyService from "../../../containers/Service/MyService";
import ServiceList from "../../../containers/Service/ServiceList";



const Service = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {/* <Route path={`${match.url}/addservice`} component={AddService}/> */}
      <Route path={`${match.url}/servicelist`} component={ServiceList}/>
      <Route path={`${match.url}/editservice/:id`} component={EditService}/>
      <Route path={`${match.url}/myservice`} component={MyService}/>
    </Switch>
  </div>
);

export default Service;
