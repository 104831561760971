import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { userSignInByKey } from "../appRedux/actions";

const SignInRemote = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const authUser = useSelector(({ auth }) => auth.authUser);

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	let key = urlParams.get('key');

	useEffect(() => {
		dispatch(userSignInByKey(key));
	}, []);

	useEffect(() => {
		if (authUser !== null) {
			history.push('/');
		}
	}, [authUser]);


	return (
		<></>
	);
};

export default SignInRemote;
