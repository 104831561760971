import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Col,
    Input,
    Layout,
    Row,
    Select,
    Form,
    Button,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getCountry } from "../../appRedux/actions/Country";
import { getCustomer } from "../../appRedux/actions/Customer";
import axios from "axios";

const { Option } = Select;

const EditCustomer = (props) => {
    const [form] = Form.useForm();
    const customerID = props.match.params.id;
    const history = useHistory();
    const [customer, setCustomer] = useState([]);
    const dispatch = useDispatch();

    let countries = useSelector(({ country }) => country.countries);
    // let { customer, status } = useSelector(({customer}) => customer);
    // let createStatus = useSelector(({customer}) => customer.status)

    // console.log(status);
    // console.log(customer);

    useEffect(() => {
        dispatch(getCustomer(customerID));
        dispatch(getCountry());
    }, []);

    const onFinish = (values) => {
        // console.log(values);
        // dispatch(updateCustomer(customerID, values));

        axios
            .post(
                config.apiserver + `customer/customerupdate/${customerID}`,
                values,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    console.log("Making Request");
                    history.push("../customerlist");
                } else {
                    console.log("error");
                    console.log("Making Request Error");
                    history.push("../customerlist");
                }
            })
            .catch((errors) => console.log(errors));
    };

    // if(status === 1) {
    //   history.push("/admin/customer/customerlist");
    // }

    const getSingleCustomer = () => {
        axios
            .get(
                config.apiserver + `customer/customeredit/${customerID}`,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    setCustomer(res.data.data);
                    console.log(res.data.data);
                } else {
                    console.log("error");
                }
            })
            .catch((errors) => console.log(errors));
    };

    useEffect(() => {
        getSingleCustomer();
    }, []);

    useEffect(() => {
        if (customer !== "") {
            form.setFieldsValue({
                user_name: customer.user_name,
                name: customer.name,
                phone: customer.phone,
                email: customer.email,
                address: customer.address,
                city: customer.city,
                state: customer.state,
                country: customer.country,
                zip_code: customer.zip_code,
                status: CisUI().getEditStatusLabel(customer.status),
            });
        }
    }, [customer, form]);

    return (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">Edit Customer</h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>Edit Customer</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                {customer === "" ? (
                                    <Loading />
                                ) : (
                                    <Form
                                        onFinish={onFinish}
                                        // initialValues={{
                                        //     user_name: customer.user_name,
                                        //     name: customer.name,
                                        //     phone: customer.phone,
                                        //     email: customer.email,
                                        //     address: customer.address,
                                        //     city: customer.city,
                                        //     state: customer.state,
                                        //     country: customer.country,
                                        //     zip_code: customer.zip_code,
                                        //     status: CisUI().getEditStatusLabel(
                                        //         customer.status
                                        //     ),
                                        // }}
                                        form={form}
                                        className="cis_form"
                                        layout="vertical"
                                    >
                                        <Row
                                            gutter={[24]}
                                            className="cis_form_row"
                                        >
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Username"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Username is required",
                                                        },
                                                    ]}
                                                    name="user_name"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="Username" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Name is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="name"
                                                >
                                                    <Input placeholder="Name" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Phone"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Phone is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="phone"
                                                >
                                                    <Input placeholder="Phone" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Email is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="email"
                                                >
                                                    <Input placeholder="Email" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Address"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Address is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="address"
                                                >
                                                    <Input placeholder="Address" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="City"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "City is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="city"
                                                >
                                                    <Input placeholder="City" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="State"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "State is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="state"
                                                >
                                                    <Input placeholder="State" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Country"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Country is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="country"
                                                >
                                                    <Select
                                                        placeholder="Select Your Country"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {countries.map(
                                                            (
                                                                country,
                                                                index
                                                            ) => (
                                                                <Option
                                                                    key={
                                                                        ++index
                                                                    }
                                                                    value={
                                                                        country.id
                                                                    }
                                                                >
                                                                    {
                                                                        country.name
                                                                    }
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Zip Code"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Zip Code is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="zip_code"
                                                >
                                                    <Input placeholder="Zip Code" />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="User Status"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "User Status is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="status"
                                                >
                                                    <Select
                                                        placeholder="Select An Option"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        name="status"
                                                    >
                                                        <Option value="1">
                                                            Active
                                                        </Option>
                                                        <Option value="0">
                                                            Inactive
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col
                                                lg={24}
                                                xs={24}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Button
                                                    type="secondary"
                                                    htmlType="reset"
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default EditCustomer;
