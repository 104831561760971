import React, { useState, useEffect } from "react";
import { Card, Col, Table } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { CisUI } from "../../util/CISUI";
import config from "../../util/config";
import { PlusOutlined } from "@ant-design/icons";
import Loading from "../Loading";

const HomeCard = () => {
	const [services, setServices] = useState([]);
	const [customers, setCustomer] = useState([]);
	const [tickets, setTickets] = useState([]);
	const [loading, setLoading] = useState(false)

	const userData = JSON.parse(CisUI().getLocalUserData());
	let userArea = userData['area'];

	const getServices = () => {
		setLoading(true)
		axios.get(config.apiserver + "dashboard", CisUI().HeaderRequest)
			.then((res) => {
				setServices(res.data.latest_services);
				setCustomer(res.data.latest_customers);
				setTickets(res.data.latest_tickets);
				setLoading(false)
			})
			.catch((errors) => {
				setLoading(false)
			});
	};
	useEffect(() => {
		getServices();
	}, []);


	const customerColumns = [
		{
			title: "SL",
			dataIndex: "sl",
			key: "sl",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Phone",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		}
	];

	const customerData = [];

	customers.map((customer, i) => {
		customerData.push({
			key: customer.id,
			sl: ++i,
			name: customer.name,
			email: customer.email,
			phone: customer.phone,
			status: CisUI().getStatusLabel(customer.status),
		});
	});

	const serviceColumns = [
		{
			title: "SL",
			dataIndex: "sl",
			key: "sl",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
		}
	];

	const serviceData = [];

	services.map((service, i) => {

		serviceData.push({
			key: service.id,
			sl: ++i,
			name: service.name,
			type: service.type,
			price: service.price,
			status: CisUI().getStatusLabel(service.status),
		});
	});

	const ticketColumns = [
		{
			title: "SL",
			dataIndex: "sl",
			key: "sl",
		},
		{
			title: "Ticket No",
			dataIndex: "ticket_no",
			key: "ticket_no",
			render: (ticket_no, record) => <Link to={`./support/view-ticket/${record.key}`}>{ticket_no}</Link>
		},
		{
			title: "Customer Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Subject",
			dataIndex: "subject",
			key: "subject",
		},
		{
			title: "Support Pin",
			dataIndex: "support_pin",
			key: "support_pin",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		}
	];

	const ticketData = [];

	tickets.map((ticket, i) => {

		ticketData.push({
			key: ticket.id,
			sl: ++i,
			name: ticket.name,
			ticket_no: ticket.ticket_no,
			subject: ticket.subject,
			support_pin: ticket.support_pin,
			status: CisUI().getTicketStatus(ticket.status),
		});
	});
	console.log(customerData);
	return (
		loading ? <Loading /> :
			<>
				{userArea === 1 ? (
					// customerData != "" && serviceData != "" && ticketData != "" ?
					<>
						<Col xl={12} lg={12} sm={22} xs={22}>
							<Card title="Customer" className="home-card" extra={<Link to="/admin/customer/addcustomer"><PlusOutlined className="dashboard-icon" /></Link>}>
								<Table scroll={{ x: true }} size={`small`} columns={customerColumns} dataSource={customerData} pagination={false} />

							</Card>
						</Col>
						<Col xl={12} lg={12} sm={22} xs={22}>
							<Card title="Services" className="home-card" extra={<Link to="/admin/service/addservice"><PlusOutlined className="dashboard-icon" /></Link>}>
								<Table scroll={{ x: true }} size={`small`} columns={serviceColumns} dataSource={serviceData} pagination={false} />
							</Card>
						</Col>
						<Col xl={12} lg={12} sm={22} xs={22}>
							<Card title="Latest Ticket" className="home-card" extra={<Link to="/admin/support/open-ticket"><PlusOutlined className="dashboard-icon" /></Link>}>
								<Table scroll={{ x: true }} size={`small`} columns={ticketColumns} dataSource={ticketData} pagination={false} />
							</Card>
						</Col>
					</>
					// : <Loading />
				) : ''}

				{userArea === 7 ? (
					// serviceData != "" || undefined || null && ticketData != "" ?
					<>
						{/* <Col xl={12} lg={12} sm={22} xs={22}>
							<Card title="Services" className="home-card">
								<Table scroll={{ x: true }} size={`small`} columns={serviceColumns} dataSource={serviceData} pagination={false} />

							</Card>
						</Col> */}
						<Col xl={12} lg={12} sm={22} xs={22}>
							<Card title="Latest Ticket" className="home-card" extra={<Link to="/customer/support/open-ticket"><PlusOutlined className="dashboard-icon" /></Link>}>
								<Table scroll={{ x: true }} size={`small`} columns={ticketColumns} dataSource={ticketData} pagination={false} />
							</Card>
						</Col>

						{/* <Col xl={12} lg={12} sm={22} xs={22}>
							<Card title="Pending Invoice" className="home-card">
								<Table size={`small`} columns={ticketColumns} dataSource={ticketData} pagination={false} />
							</Card>
						</Col> */}
					</>
					// : <Loading />
				) : ''}
			</>
	);
};

export default HomeCard;
