import React, { useState, useEffect } from "react";

import { Breadcrumb, Input, Layout, Card, Button, Table, Menu, Dropdown, Modal, } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";

import { CisUI } from "../../util/CISUI";
import { EllipsisOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { DeleteCustomer, getAllCustomer } from "../../appRedux/actions/Customer";

// const { Search } = Input;
// const onSearch = (value) => console.log(value);

const columns = [
	{
		title: "SL",
		dataIndex: "sl",
		key: "sl",
	},
	{
		title: "Name",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
	},
	{
		title: "Phone",
		dataIndex: "phone",
		key: "phone",
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
	},
	{
		title: "Actions",
		dataIndex: "action",
		key: "action",
	},
];

const CustomerList = () => {
	const [tableData, setTableData] = useState([]);
	// const history = useHistory();
	const dispatch = useDispatch();

	let customers = useSelector(({ customer }) => customer.customers)

	const handleSearch = ((e) => {
		console.log(e.target.value)

		const salesproduct = [];

		customers.map((customer, i) => {
			if (customer.name.toLowerCase().includes(e.target.value.toLowerCase()) || customer.email.toLowerCase().includes(e.target.value.toLowerCase()) || customer.phone.toLowerCase().includes(e.target.value.toLowerCase())) {
				salesproduct.push({
					key: customer.id,
					sl: ++i,
					name: customer.name,
					email: customer.email,
					phone: customer.phone,
					status: CisUI().getStatusLabel(customer.status),
					action: (
						<Dropdown overlay={actionMenu(customer.id)} placement="bottomCenter">
							<Button className="list-table-btn" size="small">
								<EllipsisOutlined />
							</Button>
						</Dropdown>
					),
				});
			}
		})
		setTableData(salesproduct)
	});

	const getTableData = () => {
		const data = [];

		customers.map((customer, i) => {
			data.push({
				key: customer.id,
				sl: ++i,
				name: customer.name,
				email: customer.email,
				phone: customer.phone,
				status: CisUI().getStatusLabel(customer.status),
				action: (
					<Dropdown overlay={actionMenu(customer.id)} placement="bottomCenter">
						<Button className="list-table-btn" size="small">
							<EllipsisOutlined />
						</Button>
					</Dropdown>
				),
			});
		});
		setTableData(data)
	}

	useEffect(() => {
		getTableData();
	}, [customers]);

	const actionMenu = (id) => {
		const menu = (
			<Menu>
				<Menu.Item key={`edit_` + id}>
					<Link to={`/admin/customer/editcustomer/${id}`}>
						<Button
							size="small"
							type="primary"
							className="list-table-btn"
							block
						>
							Edit
						</Button>
					</Link>
				</Menu.Item>
				<Menu.Item key={`delete_` + id}>
					<Button
						size="small"
						type="primary"
						className="list-table-btn"
						block
						onClick={() => {
							showConfirm(id);
						}}
					>
						Delete
					</Button>
				</Menu.Item>
			</Menu>
		);

		return menu;
	};

	function showConfirm(customerID) {
		Modal.confirm({
			title: "Do you Want to delete these items?",
			content: "Some descriptions",
			okText: "Ok",
			cancelText: "Cancel",
			onOk() {
				dispatch(DeleteCustomer(customerID))
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	}

	useEffect(() => {
		dispatch(getAllCustomer());
	}, []);


	return (
		<main className="ant-layout-content gx-layout-content gx-container-wrap ">
			<div className="home-main-content">
				<Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
					<div className="gx-algolia-main">
						<h1 className="home-top-header">Customers<Link to="./addcustomer"><Button className="title-btn" icon={<PlusOutlined />} /></Link></h1>
						<Breadcrumb className="home-top-breadcrumb">
							<Breadcrumb.Item>
								<Link to="/">Portal Home</Link>
							</Breadcrumb.Item>
							<Breadcrumb.Item>Customers</Breadcrumb.Item>
						</Breadcrumb>
						<Content className="gx-algolia-content">
							<div className="gx-algolia-content-inner">
								<Card className="tickets-card">
									<Card
										type="inner"
										
										extra={
											<Input onChange={handleSearch} size="large" placeholder="Search Ticket" addonAfter={<SearchOutlined />} />
										}
									>
										<Table scroll={{ x: true }} columns={columns} dataSource={tableData} />
									</Card>
								</Card>
							</div>
						</Content>
					</div>
				</Layout>
			</div>
		</main>
	);
};

export default CustomerList;
