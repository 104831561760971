import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Input, Layout, Row, Select, Form } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { Button } from "antd";
import axios from "axios";
import config from "../../util/config";
import { CisUI } from "../../util/CISUI";
import Loading from "../../components/Loading";
import { MenuOutlined } from "@ant-design/icons";
import CKEditor from "react-ckeditor-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomer } from "../../appRedux/actions/Customer";
import { useHistory } from "react-router-dom";

const { Option } = Select;
const OpenTicket = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const getuserdata = CisUI().getLocalUserData();
    const [ckeEdit, setckeEdit] = useState("");
    const parseData = JSON.parse(getuserdata);
    const profileID = parseData.id;
    const area = parseData.area;
    const customer_id = parseData.customer_id;

    const dispatch = useDispatch();
    let customers = useSelector(({ customer }) => customer.customers);
    const [profile, setProfile] = useState([]);
    const [file, setImage] = useState("");

    const [services, setServices] = useState([]);

    const [loading, setLoading] = useState(false);

    const getServices = () => {
        axios
            .get(
                config.apiserver + "service/servicelist",
                CisUI().HeaderRequest
            )
            .then((res) => setServices(res.data.data))
            .catch((errors) => console.log(errors));
    };

    useEffect(() => {
        dispatch(getAllCustomer());
        getServices();
    }, []);

    // const history = useHistory();
    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            setImage({
                file: e.target.result,
            });
        };
        // console.log(file);
        reader.readAsDataURL(file);
    };

    const onFinish = (values) => {
        // console.log(values);
        setLoading(true);
        const data = {
            ...values,
            customer_id: values["customer_id"]
                ? values["customer_id"]
                : customer_id,
            file: file,
            details: ckeEdit,
            priority: undefined ? "Medium" : values.priority,
        };
        console.log(data);
        axios
            .post(
                config.apiserver + `customersupport/newcustomersupport`,
                data,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    setLoading(false);
                    CisUI().Notification("success", res.data.msg);
                    history.push("./ticketlist");
                } else {
                    setLoading(false);
                    CisUI().Notification("warning", res.data.msg);
                }
            })
            .catch((errors) => {
                setLoading(false);
                CisUI().Notification("error", "Ticket Not Created");
            });
    };
    const getProfileData = () => {
        axios
            .get(
                config.apiserver + `settings/showprofile/${profileID}`,
                CisUI().HeaderRequest
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.status === 1) {
                    setProfile(res.data.data);
                    // console.log(res.data.data);
                } else {
                    console.log("error");
                }
            })
            .catch((errors) => console.log(errors));
    };
    useEffect(() => {
        getProfileData();
        // getCountries();
    }, []);

    const handleChange = (evt) => {
        var newContent = evt.editor.getData();
        setckeEdit(newContent);
    };

    useEffect(() => {
        if (profile) {
            form.setFieldsValue({
                name: profile.name,
                email: profile.email,
            });
        }
    }, [area, customer_id, form, profile]);
    return loading ? (
        <Loading />
    ) : (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">
                            Open Ticket
                            <Link to="./ticketlist">
                                <Button
                                    className="title-btn"
                                    icon={<MenuOutlined />}
                                />
                            </Link>
                        </h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Submit Ticket</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                {profile === "" ? (
                                    <Loading />
                                ) : (
                                    <Form
                                        onFinish={onFinish}
                                        // initialValues={{
                                        //     name: profile.name,
                                        //     email: profile.email,
                                        // }}
                                        form={form}
                                        className="cis_form"
                                        layout="vertical"
                                    >
                                        <Row
                                            // className="ant-row"
                                            // style={{ rowGap: "15px" }}
                                            // justify="space-between"
                                            gutter={[24]}
                                            className="cis_form_row"
                                        >
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Name is required",
                                                        },
                                                    ]}
                                                    name="name"
                                                    className="form-lblock"
                                                >
                                                    <Input
                                                        placeholder="Name"
                                                        disabled={true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Email is required",
                                                        },
                                                    ]}
                                                    name="email"
                                                    className="form-lblock"
                                                >
                                                    <Input
                                                        placeholder="Email"
                                                        disabled={true}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Subject"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Subject is required",
                                                        },
                                                    ]}
                                                    name="subject"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="Subject" />
                                                </Form.Item>
                                            </Col>
                                            {/* <Col lg={12} xs={24}>
													<Form.Item
														label="Area"
														rules={[
															{ required: true, message: "Area is required" },
														]}
														name="area"
														className="form-lblock"
													>
														<Select
															placeholder="Please select your Area"
															style={{ width: "100%" }}
														>
															<Option value="bd">Bangladesh</Option>
															<Option value="usa">U.S.A</Option>
															<Option value="ghana">Ghana</Option>
														</Select>
													</Form.Item>
												</Col> */}
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Department"
                                                    name="department"
                                                    className="form-lblock"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Department is required",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder="Please select your department"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Option value="technical-support">
                                                            Technical Support
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            {area === 1 && (
                                                <Col lg={12} xs={24}>
                                                    <Form.Item
                                                        label="Customer"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Customer is required",
                                                            },
                                                        ]}
                                                        name="customer_id"
                                                        className="form-lblock"
                                                    >
                                                        <Select
                                                            placeholder="Please select an option"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {customers.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <Option
                                                                        key={
                                                                            ++index
                                                                        }
                                                                        value={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            )}

                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Related Service"
                                                    // rules={[
                                                    // 	{
                                                    // 		required: true,
                                                    // 		message: "Related Service is required",
                                                    // 	},
                                                    // ]}
                                                    name="service_id"
                                                    className="form-lblock"
                                                >
                                                    <Select
                                                        placeholder="Please select your service"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {services.map(
                                                            (
                                                                service,
                                                                index
                                                            ) => (
                                                                <Option
                                                                    key={
                                                                        ++index
                                                                    }
                                                                    value={
                                                                        service.id
                                                                    }
                                                                >
                                                                    {
                                                                        service.name
                                                                    }
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Priority"
                                                    name="priority"
                                                    className="form-lblock"
                                                    initialValue="Medium"
                                                >
                                                    <Select
                                                        placeholder="Please select priority"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Option value="High">
                                                            High
                                                        </Option>
                                                        <Option value="Medium">
                                                            Medium
                                                        </Option>
                                                        <Option value="Low">
                                                            Low
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={24} xs={24}>
                                                {/* <Form.Item
													label="Message"
													rules={[
														{
															required: true,
															message: "Message is required",
														},
													]}
													name="details"
													className="form-lblock"
												>
													<TextArea rows={5} />
												</Form.Item> */}
                                                <Form.Item
                                                    label="Message"
                                                    className="form-lblock"
                                                >
                                                    <CKEditor
                                                        activeClass="p10"
                                                        content={ckeEdit}
                                                        events={{
                                                            change: handleChange,
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={24} xs={24}>
                                                <Form.Item
                                                    label="File Attachment"
                                                    name="file"
                                                    className="form-lblock"
                                                >
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        onChange={changeImage}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                lg={24}
                                                xs={24}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Button
                                                    type="secondary"
                                                    htmlType="reset"
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};
export default OpenTicket;
