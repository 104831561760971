import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GET_ALL_SERVICE,
    CREATE_SERVICE,
    GET_SERVICE_DETAILS,
    UPDATE_SERVICE,
    DELETE_SERVICE,
} from "../../constants/ActionTypes";
import axios from "axios";
import { CisUI } from "../../util/CISUI";
import Config from "../../util/config";

export const getAllService = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .get(
                Config.apiserver + "service/servicelist",
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    // console.log(res.data.data);
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: GET_ALL_SERVICE, payload: res.data.data });
                } else {
                    dispatch({ type: FETCH_ERROR, payload: res.data.msg });
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_SUCCESS });
                console.log("Error****:", error.message);
            });
    };
};

export const addService = (values) => async (dispatch) => {
    return await axios
        .post(
            Config.apiserver + "service/newservice",
            values,
            CisUI().HeaderRequest
        )
        .then((res) => {
            if (res.data.status === 1) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: CREATE_SERVICE, payload: values });
                CisUI().Notification("success", res.data.msg);
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.msg });
                CisUI().Notification("error", res.data.msg);
            }
        })
        .catch(function (error) {
            dispatch({ type: FETCH_ERROR });
            console.log("Error****:", error.message);
        });
};

export const updateService = (id, values) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .post(
                Config.apiserver + "service/serviceupdate/" + id,
                values,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    // console.log(res.data.data);
                    // const updateData = getCustomer(id);

                    // console.log( dispatch(getService(id)));
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: UPDATE_SERVICE, payload: { id, values } });
                    dispatch(getService(id));
                    // dispatch(getAllService())
                    CisUI().Notification("success", res.data.msg);
                } else {
                    dispatch({ type: FETCH_ERROR, payload: res.data.msg });
                    CisUI().Notification("error", res.data.msg);
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR });
                console.log("Error****:", error.message);
            });
    };
};

export const getService = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .get(
                Config.apiserver + "service/serviceedit/" + id,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    // console.log(res.data.data);
                    // let da = res.data.data;
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({
                        type: GET_SERVICE_DETAILS,
                        payload: res.data.data,
                    });
                } else {
                    dispatch({ type: FETCH_ERROR, payload: res.data.msg });
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR });
                console.log("Error****:", error.message);
            });
    };
};

export const DeleteService = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .delete(
                Config.apiserver + "service/servicedelete/" + id,
                CisUI().HeaderRequest
            )
            .then((res) => {
                if (res.data.status === 1) {
                    console.log(res.data.data);
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: DELETE_SERVICE, payload: { id } });
                    CisUI().Notification("success", res.data.msg);
                } else {
                    dispatch({ type: FETCH_ERROR, payload: res.data.msg });
                    CisUI().Notification("error", res.data.msg);
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR });
                console.log("Error****:", error.message);
            });
    };
};
