import React, { useState, useEffect } from "react";
import { Breadcrumb, Col, Input, Layout, Row, Select, Form } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { Button } from "antd";
import Config from "../../util/config";
import Loading from "../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileData, updateProfile } from "../../appRedux/actions";
import { getCountry } from "../../appRedux/actions/Country";

const { Option } = Select;

const ProfileSettings = () => {
    const [form] = Form.useForm();
    //   const serviceID = props.match.params.id;
    // const history = useHistory();
    const [image, setImage] = useState("");

    const user = JSON.parse(localStorage.getItem("userData"));
    const userID = user["id"];

    const dispatch = useDispatch();
    let userData = useSelector((state) => state.auth.profileData);
    let countries = useSelector(({ country }) => country.countries);

    console.log(userData);

    useEffect(() => {
        dispatch(getUserProfileData(userID));
        dispatch(getCountry());
    }, [userID]);

    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            setImage({
                image: e.target.result,
            });
        };
        reader.readAsDataURL(file);
    };

    const onFinish = (values) => {
        const data = {
            ...values,
            image: image,
        };
        dispatch(updateProfile(data, userID));
    };

    useEffect(() => {
        if (userData) {
            form.setFieldsValue({
                name: userData.name,
                phone: userData.phone,
                user_name: userData.user_name,
                email: userData.email,
                address: userData.address,
                country: userData.country,
                city: userData.city,
            });
        }
    }, [form, userData]);

    return (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">
                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">Profile Settings</h1>
                        <Breadcrumb className="home-top-breadcrumb">
                            <Breadcrumb.Item>
                                <Link to="/">Portal Home</Link>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>Profile Settings</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                {userData === "" ? (
                                    <Loading />
                                ) : (
                                    <Form
                                        onFinish={onFinish}
                                        // initialValues={{
                                        //     name: userData.name,
                                        //     phone: userData.phone,
                                        //     user_name: userData.user_name,
                                        //     email: userData.email,
                                        //     address: userData.address,
                                        //     country: userData.country,
                                        //     city: userData.city,
                                        // }}
                                        form={form}
                                        className="cis_form"
                                        layout="vertical"
                                    >
                                        <Row
                                            // className="ant-row"
                                            // style={{ rowGap: "15px" }}
                                            // justify="space-between"
                                            gutter={[24, 24]}
                                        >
                                            {/* Name */}
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Name is required",
                                                        },
                                                    ]}
                                                    name="name"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="Name" />
                                                </Form.Item>
                                            </Col>

                                            {/* Username */}
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Username"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "UserName is required",
                                                        },
                                                    ]}
                                                    name="user_name"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="Username" />
                                                </Form.Item>
                                            </Col>

                                            {/* Phone */}
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Phone"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Phone is required",
                                                        },
                                                    ]}
                                                    name="phone"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="Phone" />
                                                </Form.Item>
                                            </Col>
                                            {/* Email */}
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Email is required",
                                                        },
                                                    ]}
                                                    name="email"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="Email" />
                                                </Form.Item>
                                            </Col>
                                            {/* Address */}
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Address"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Address is required",
                                                        },
                                                    ]}
                                                    name="address"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="Address" />
                                                </Form.Item>
                                            </Col>
                                            {/* Country */}
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="Country"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Country is required",
                                                        },
                                                    ]}
                                                    className="form-lblock"
                                                    name="country"
                                                >
                                                    <Select
                                                        placeholder="Select Your Country"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {countries.map(
                                                            (
                                                                country,
                                                                index
                                                            ) => (
                                                                <Option
                                                                    key={
                                                                        ++index
                                                                    }
                                                                    value={
                                                                        country.id
                                                                    }
                                                                >
                                                                    {
                                                                        country.name
                                                                    }
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            {/* City */}
                                            <Col lg={12} xs={24}>
                                                <Form.Item
                                                    label="City"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "City is required",
                                                        },
                                                    ]}
                                                    name="city"
                                                    className="form-lblock"
                                                >
                                                    <Input placeholder="City" />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                lg={12}
                                                md={12}
                                                sm={24}
                                                xs={24}
                                            >
                                                {/*<div className="ant-row ant-form-item form-lblock" style="row-gap: 0px;">*/}
                                                {/*	<div className="ant-col ant-form-item-label">*/}
                                                {/*		<label htmlFor="image" className="ant-form-item-required" title="Address">Address</label>*/}
                                                {/*	</div>*/}
                                                {/*	<div className="ant-col ant-form-item-control">*/}
                                                {/*		<div className="ant-form-item-control-input">*/}
                                                {/*			<div className="ant-form-item-control-input-content">*/}
                                                {/*				<input type="file" id="image" className="ant-input" name="image" onChange={changeImage}/>*/}
                                                {/*			</div>*/}
                                                {/*		</div>*/}
                                                {/*	</div>*/}
                                                {/*</div>*/}

                                                <div className="nt-row ant-form-item form-lblock">
                                                    <div className="ant-col ant-form-item-label">
                                                        <label
                                                            htmlFor="image"
                                                            className="ant-form-item-required"
                                                            title="Address"
                                                        >
                                                            Image
                                                        </label>
                                                    </div>
                                                    <input
                                                        name="image"
                                                        type="file"
                                                        onChange={changeImage}
                                                    />
                                                    {userData.image != null && (
                                                        <img
                                                            src={
                                                                Config.profile_img +
                                                                userData.image
                                                            }
                                                            alt="cis"
                                                            style={{
                                                                width: "120px",
                                                                height: "100px",
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </Col>
                                            <Col
                                                lg={24}
                                                xs={24}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Button
                                                    type="secondary"
                                                    htmlType="reset"
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Change Profile Settings
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default ProfileSettings;
